import "../App.scss";
import { useState, useEffect } from "react";
import axios from "axios";

export default function History() {
  const [data, setData] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
      axios.get("https://nitrous.rt-itservices.co.uk/visitors/checkedOut")
      .then((res) => {
        setData(res.data)
      }).catch((err) => {
          console.log(err)
      })
  }, [reload]);

  const rel = () => {
    setReload(!reload);
  };

  return (
    <section className="status">
      <table id="entries">
        <tbody>
          <tr>
            <th>Site</th>
            <th>Checked in time</th>
            <th>Name</th>
            <th>Company</th>
            <th>Reason</th>
            <th>Checked out</th>
          </tr>
          {data ? (
            data.map(function (d, i) {
              return (
                <tr>
                  <td>{d.site}</td>
                  <td>{d.checkInTime}</td>
                  <td>{d.name}</td>
                  <td>{d.company}</td>
                  <td>{d.reason}</td>
                  <td>{d.checkOutTime}</td>
                </tr>
              );
            })
          ) : (
            <></>
          )}
        </tbody>
      </table>
    </section>
  );
}
