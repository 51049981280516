import "../App.scss";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import IconButton from "@mui/material/IconButton";
import axios from 'axios';
import { useState } from 'react';

export default function IndividualStatus(props) {
  const [reload, setReload] = useState(false);


  function handleLogOut() {
    var data = {
      name: props.d.name,
      company: props.d.company,
      id: props.d._id
    }
    axios.post("https://nitrous.rt-itservices.co.uk/visitors/out", data)
      .then((res) => {
        console.log(res.data)
        setReload(!reload);
        alert("Logged Out")
        props.rel();
      }).catch((err) => {
        console.log(err)
      })
  }

  return (
    <tr>
      <td>{props.d.site}</td>
      <td>{props.d.checkInTime}</td>
      <td>{props.d.name}</td>
      <td>{props.d.company}</td>
      <td>{props.d.reason}</td>
      {props.d.checkedOut ?
        <td>{props.d.checkOutTime
        }</td>
        :
        <td>
          <IconButton onClick={() => handleLogOut()}>
            <ExitToAppIcon sx={{ color: "#be1a1a" }} />
          </IconButton>
        </td>
      }
    </tr>
  );
}
