import logo from "../images/rt-logo.webp";


export default function Header() {
    return(
        <header style={{"width":"99%"}}>
            <img src={logo} />
            <h1>Visitor System</h1>
        </header>
    )
}