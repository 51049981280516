import React, { useState, useEffect } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios'
import '../Styles/form.css'

export default function Form() {
    const [site, setSite] = useState("")
    const [sites, setSites] = useState([])
    const [showSite, setShowSite] = useState(true)
    const [name, setName] = useState("")
    const [showName, setShowName] = useState(false)
    const [company, setCompany] = useState("")
    const [reason, setReason] = useState("")
    const [showReason, setShowReason] = useState(false)
    const [signature, setSignature] = useState("")
    const [showSignature, setShowSignature] = useState(false)

    useEffect(() => {
        axios
            .get("https://nitrous.rt-itservices.co.uk/sites/data")
            .then((res) => {
                setSites(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [])

    const checkIn = async () => {
        const signature64 = signature.getTrimmedCanvas().toDataURL('image/png')
        axios.post("https://nitrous.rt-itservices.co.uk/visitors/add", { name: name, site: site, company: company, reason: reason, signature: signature64 }).then(() => {
            alert("Visitor Successfully Checked In!");
            window.location.reload();
        }).catch((err) => {
            alert("Error! Please Try Again Later!")
        })
    }

    return (
        <>
            <h1><u>Visitor Form</u></h1>
            {showSite ?
                <div>
                    <select className="siteSelection" value={site} onChange={(e) => setSite(e.target.value)}>
                        <option>--- Select Site ---</option>
                        {sites.map(function(d,i){
                            return(
                                <option>{d.name}</option>
                            )
                        })}
                    </select><hr />
                    <button className="formBtn" onClick={() => { setShowSite(false); setShowName(true) }}>Next</button>
                </div>
                : <></>}
            {showName ?
                <div>
                    <p><u>Your Name</u></p>
                    <input className="visitorName" type="text" value={name} placeholder="Enter Your Name..." onChange={(e) => { setName(e.target.value) }} /><br />
                    <p><u>Your Company</u></p>
                    <input className="visitorName" type="text" value={company} placeholder="Enter Your Company..." onChange={(e) => { setCompany(e.target.value) }} /><hr />
                    <button className="formBtn" onClick={() => { setShowName(false); setShowSite(true) }}>Back</button>
                    <button className="formBtn" onClick={() => { setShowName(false); setShowReason(true) }}>Next</button>
                </div>
                : <></>}
            {showReason ?
                <div>
                    <label>Reason For Visit</label><br />
                    <textarea className="reasonBox" rows="4" cols="40" placeholder="Enter Reason Here..." onChange={(e) => { setReason(e.target.value) }}></textarea><hr />
                    <button className="formBtn" onClick={() => { setShowReason(false); setShowName(true) }}>Back</button>
                    <button className="formBtn" onClick={() => { setShowReason(false); setShowSignature(true) }}>Next</button>
                </div>
                : <></>}
            {showSignature ?
                <div>
                    <label>Signature</label><br />
                    <SignatureCanvas penColor='black' canvasProps={{ className: 'sigCanvas' }} ref={(ref) => { setSignature(ref) }} /><br />
                    <button className="formBtn" onClick={() => { setShowSignature(false); setShowReason(true) }}>Back</button>
                    <button className="formBtn checkInBtn" onClick={() => { checkIn() }}>Check In</button>
                </div>
                : <></>}

        </>
    )
}