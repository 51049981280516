import "../App.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import IndividualStatus from "./IndividualStatus";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";

export default function Status() {
  const [data, setData] = useState("");
  const [reload, setReload] = useState(false);
  const [sites, setSites] = useState([]);
  const [results, setResults] = useState([]);
  const [filterResult, setFilterResult] = useState(false);

  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/visitors/")
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get("https://nitrous.rt-itservices.co.uk/sites/data")
      .then((res) => {
        setSites(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reload]);

  const rel = () => {
    setReload(!reload);
  };

  async function handleFilter(choice) {
    if (choice === "--- Select Site ---") {
      window.location.reload();
    } else {
      await axios
        .get(`https://nitrous.rt-itservices.co.uk/visitors/site?site=${choice}`)
        .then((res) => {
          console.log(res.data);
          setResults(res.data);
          setFilterResult(true)
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  return (
    <section className="status">
      <p>Filter visitors by site:</p>
      <div className="line">
        <select
          className="siteSelection"
          onChange={(e) => handleFilter(e.target.value)}
          style={{ marginBottom: "2%" }}
        >
          <option>--- Select Site ---</option>
          {sites.map(function (d, i) {
            return (
              <option>{d.name}</option>
            )
          })}
        </select>
        {/* <IconButton onClick={() => handleFilter()}>
          <SearchIcon fontSize="large" />
        </IconButton> */}
      </div>
      {filterResult ? (
        <table>
          <tbody>
            <tr>
              <th>Site</th>
              <th>Checked in time</th>
              <th>Name</th>
              <th>Company</th>
              <th>Reason</th>
              <th>Check out</th>
            </tr>
            {results.map(function (d, i) {
              return <IndividualStatus d={d} rel={rel} />;
            })}
          </tbody>
        </table>
      ) : (
        <table id="entries">
          <tbody>
            <tr>
              <th>Site</th>
              <th>Checked in time</th>
              <th>Name</th>
              <th>Company</th>
              <th>Reason</th>
              <th>Check out</th>
            </tr>
            {data ? (
              data.map(function (d, i) {
                return <IndividualStatus d={d} rel={rel} />;
              })
            ) : (
              <></>
            )}
          </tbody>
        </table>
      )}
    </section>
  );
}
